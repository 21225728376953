import React, { useEffect, useState } from 'react';
import { DiscountAttributesTypes, Props } from './types';
import { SchoolProductsState } from 'store/edupay/schoolProducts/types';
import { useDispatch, useSelector } from 'react-redux';
import tabify from 'core/hoc/tabify';
import withAppContext from 'core/hoc/withAppContext';

import {
  renderPenalityMessage,
  renderDiscountMessage,
} from 'core/utils/edupay/functions';
import ButtonRow from 'components/ButtonRow';
import ClassroomsList from 'components/ClassroomsList';
import Currency from 'components/Currency';
import LabeledContainer from 'components/LabeledContainer';
import Loader from 'components/Loader';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import ModalSubTitle from 'components/Modal/ModalSubTitle';
import OutlineBox from 'components/OutlineBox';
import PageTitle from 'components/PageTitle';
import SimpleSummary from 'components/Payments/PaymentsContainer/FormSections/SimpleSummary';

import { getButtons } from './SchoolProductInformationsButtons';
import actionSchoolProducts from 'store/edupay/schoolProducts/actions';

import * as S from './styles';

const SchoolProductInformationsTab = ({
  appContext: { policies },
  schoolProductId,
}: Props) => {
  const dispatch = useDispatch();

  const [actionModal, setActionModal] = useState<string>('approve');

  const { edupay_provider } = policies;

  const {
    isLoading,
    isLoadingTaxAmount,
    currentSchoolProduct,
    showModalScholProductInformation,
  } = useSelector((state: SchoolProductsState) => state.schoolProducts);

  const {
    fetchCurrentSchoolProduct,
    fetchCurrentTaxAmount,
    toggleInformationModal,
    setApproveSchoolProduct,
    setDeleteSchoolProduct,
    setCancelSchoolProduct,
  } = actionSchoolProducts;

  useEffect(() => {
    !!showModalScholProductInformation && toggleModal();
    dispatch(fetchCurrentSchoolProduct({ schoolProductId }));
  }, []);

  useEffect(() => {
    if (!isLoading && currentSchoolProduct) {
      const params = {
        amount: currentSchoolProduct.attributes.price,
        percent_tax: currentSchoolProduct.attributes.percent_tax,
      };
      dispatch(fetchCurrentTaxAmount(params));
    }
  }, [isLoading]);

  const toggleModal = () => dispatch(toggleInformationModal());

  const changeActionModal = (action: string) => setActionModal(action);

  const approveSchoolProduct = () =>
    dispatch(setApproveSchoolProduct({ schoolProductId }));

  const deleteSchoolProduct = () =>
    dispatch(setDeleteSchoolProduct({ schoolProductId }));

  const cancelSchoolProduct = () =>
    dispatch(setCancelSchoolProduct({ schoolProductId }));

  const getStatus = () => {
    const statusOutline = {
      pending: {
        text: 'Pendente',
        variation: 'warning',
      },
      sent: {
        text: currentSchoolProduct?.attributes?.source_api_v2
          ? `Criado e aprovado via API: ${currentSchoolProduct.attributes.approved_at}`
          : `Aprovado: ${currentSchoolProduct.attributes.approved_at}`,
      },
      rejected: {
        text: 'Não aprovado',
        variation: 'danger',
      },
      canceled: {
        text: `Cancelado: ${currentSchoolProduct.attributes.canceled_at}`,
        variation: 'dimming',
      },
      deleted: {
        text: 'Deletado',
      },
      expired: {
        text: 'Encerrado',
      },
    };

    return (
      <OutlineBox
        variation={
          statusOutline[currentSchoolProduct.attributes.status].variation
        }
      >
        {statusOutline[currentSchoolProduct.attributes.status].text}
      </OutlineBox>
    );
  };

  const getModalData = () => {
    const modalData = {
      approve: {
        buttons: [
          {
            text: 'Cancelar',
            variation: 'secondary',
            onClick: toggleModal,
          },
          {
            text: 'Aprovar envio',
            onClick: approveSchoolProduct,
          },
        ],
        header: (
          <div>
            <ModalTitle>Deseja aprovar o envio da cobrança?</ModalTitle>
            <ModalSubTitle subTitle="Uma cobrança será enviada para os responsáveis dos alunos selecionados." />
          </div>
        ),
      },
      delete: {
        buttons: [
          {
            text: 'Cancelar',
            variation: 'secondary',
            onClick: toggleModal,
          },
          {
            text: 'Excluir',
            onClick: deleteSchoolProduct,
          },
        ],
        header: (
          <div>
            <ModalTitle>Deseja excluir esse cobrança?</ModalTitle>
            <ModalSubTitle subTitle="As informações serão excluídas permanentemente." />
          </div>
        ),
      },
      cancel: {
        buttons: [
          {
            text: 'Cancelar',
            variation: 'secondary',
            onClick: toggleModal,
          },
          {
            text: 'Confirmar',
            onClick: cancelSchoolProduct,
          },
        ],
        header: (
          <div>
            <ModalTitle>Deseja cancelar a cobrança?</ModalTitle>
            <ModalSubTitle subTitle="Ao cancelar uma cobrança, não serão permitidos novos pagamentos." />
          </div>
        ),
      },
      expired: {
        buttons: [
          {
            text: 'Cancelar',
            variation: 'secondary',
            onClick: toggleModal,
          },
          {
            text: 'Editar cobrança',
            path: `/schools/school_products/${schoolProductId}/edit`,
          },
        ],
        header: (
          <div>
            <ModalTitle>Não foi possível aprovar o envio</ModalTitle>
            <ModalSubTitle
              subTitle="A data de vencimento definida já passou. Edite a cobrança e
                defina uma nova data de vencimento, para conseguir realizar o envio."
            />
          </div>
        ),
      },
      clone: {
        buttons: [
          {
            text: 'Cancelar',
            variation: 'secondary',
            onClick: toggleModal,
          },
          {
            text: 'Duplicar',
            path: `/schools/school_products/${schoolProductId}/clone_charge`,
          },
        ],
        header: (
          <div>
            <ModalTitle>Duplicar cobrança</ModalTitle>
            <ModalSubTitle
              subTitle="Será criado uma nova cobrança com as mesmas informações.
              Tem certeza que deseja duplicar?"
            />
          </div>
        ),
      },
    };

    return modalData[actionModal];
  };

  const modalData = getModalData();

  return (
    <div>
      {isLoading || isLoadingTaxAmount || !currentSchoolProduct ? (
        <Loader />
      ) : (
        <S.SchoolProductInformationsWrapper data-testid="wrapper">
          <S.SchoolProductStatus>
            {getStatus()}
            {currentSchoolProduct.attributes.status === 'rejected' && (
              <S.CancelationDescription>
                <strong>Motivo:</strong>
                {currentSchoolProduct.attributes.cancellation_description}
              </S.CancelationDescription>
            )}
          </S.SchoolProductStatus>
          <PageTitle>{currentSchoolProduct.attributes.title}</PageTitle>
          <S.InformationBox>
            <S.LeftSide>
              <LabeledContainer title="Destinatários">
                <div className="classrooms-students">
                  <ClassroomsList
                    classroomsNames={
                      currentSchoolProduct.attributes.classroom_names
                    }
                  />
                  <OutlineBox>
                    {currentSchoolProduct.attributes.total_students}{' '}
                    {`${
                      parseInt(
                        currentSchoolProduct.attributes.total_students,
                        10
                      ) === 1
                        ? 'aluno'
                        : 'alunos'
                    }`}
                  </OutlineBox>
                </div>
              </LabeledContainer>
              {currentSchoolProduct.attributes.legacy_id && (
                <LabeledContainer title="Legacy id">
                  <div> {currentSchoolProduct.attributes.legacy_id}</div>
                </LabeledContainer>
              )}
              <LabeledContainer title="Data de vencimento">
                <div>{currentSchoolProduct.attributes.expires_at}</div>
              </LabeledContainer>
              {currentSchoolProduct.attributes.has_discount && (
                <LabeledContainer title="Desconto de pontualidade">
                  <div className="unique-column">
                    <ul>
                      {currentSchoolProduct.attributes.edupay_discounts.data.map(
                        ({ attributes }) => (
                          <>{renderDiscountMessage(attributes)}</>
                        )
                      )}
                    </ul>
                  </div>
                </LabeledContainer>
              )}
              <>
                {currentSchoolProduct.attributes.has_penality && (
                  <LabeledContainer title="Multas e juros">
                    <div className="penality">
                      {renderPenalityMessage(
                        currentSchoolProduct.attributes.edupay_penality.data
                          .attributes
                      )}
                    </div>
                  </LabeledContainer>
                )}
              </>
              {currentSchoolProduct.attributes.allowed_payment_method.includes(
                'credit_card'
              ) && (
                <LabeledContainer title="Parcelamento">
                  {`Em até ${currentSchoolProduct.attributes.max_installments} `}
                  {currentSchoolProduct.attributes.max_installments === 1
                    ? 'vez'
                    : 'vezes'}
                </LabeledContainer>
              )}
              <LabeledContainer title="Pagamentos">
                <span>{`${currentSchoolProduct.attributes.total_paid} de ${currentSchoolProduct.attributes.total_students}`}</span>
              </LabeledContainer>
              {edupay_provider === 'bemobi' && (
                <LabeledContainer title="Condição de parcelamento">
                  <span>
                    {currentSchoolProduct.attributes?.settings?.absorb_tax
                      ? `${currentSchoolProduct.attributes.max_installments}x com taxas para a escola`
                      : `${currentSchoolProduct.attributes.max_installments}x com taxa única para a escola`}
                  </span>
                </LabeledContainer>
              )}
              <LabeledContainer title="Descrição">
                <S.SchoolProductDetailsDescription
                  dangerouslySetInnerHTML={{
                    __html: currentSchoolProduct.attributes.description,
                  }}
                />
              </LabeledContainer>
            </S.LeftSide>
            <S.RightSide>
              <LabeledContainer title="Valores">
                <S.AccordionWrapper>
                  <SimpleSummary
                    price={currentSchoolProduct?.attributes?.price}
                  />
                </S.AccordionWrapper>
              </LabeledContainer>
            </S.RightSide>
          </S.InformationBox>
          <S.FooterDivider />
          <S.FooterButtonWrapper>
            <ButtonRow
              buttons={getButtons({
                policies,
                toggleModal,
                currentSchoolProduct,
                changeActionModal,
              })}
            />
          </S.FooterButtonWrapper>
          <ModalContainer
            isOpen={showModalScholProductInformation}
            toggleModal={toggleModal}
            maxWidth="490px"
          >
            {modalData.header}
            {modalData.content}
            <ButtonRow buttons={modalData.buttons} />
          </ModalContainer>
        </S.SchoolProductInformationsWrapper>
      )}
    </div>
  );
};

export default tabify({
  title: 'Informações',
  icon: 'info',
})(withAppContext(SchoolProductInformationsTab));
