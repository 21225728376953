import React, { useCallback } from 'react';
import {
  Box,
  Card as CardComponent,
  DefaultThemeProps,
  FlexGrid,
  FlexGridItem,
  RecipientTag,
  SubtleIconButton,
  Tag,
  Text,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import * as S from './styles';

import { getRecipientTag, getSchedule } from 'core/helper/calendar/event';
import {
  EVENT_STATUS,
  EVENT_TAG_STATUS_VARIANT,
} from 'core/constants/calendar/event';

import { Props } from './types';

const Card: React.FC<Props> = ({
  actions,
  event,
  history,
}): React.ReactElement => {
  const { t } = useTranslation(['calendar']);
  const { colors } = useTheme() as DefaultThemeProps;

  const tBase = useCallback((key: string) => t(`list.card.${key}`), [t]);

  const dataArea = useSelector((state: RootStateOrAny) => state.root.dataArea);

  const {
    relationships: { classrooms, studentProfiles },
    attributes: { title, receiverType, status },
  } = event;

  const handleNavigateToDetails = useCallback(() => {
    history.push(`/${dataArea}/calendar_events/${event.id}`);
  }, [dataArea, event, history]);

  const indicatorColor = colors.brand.primary.default;

  const currentRecipientTag = getRecipientTag({
    classrooms,
    studentProfiles,
    receiverType,
  });

  const schedule = getSchedule(event.attributes);

  return (
    <CardComponent
      alignItems="center"
      id="event-card"
      indicatorColor={indicatorColor}
      flexDirection="row"
      width="100%"
      onClick={handleNavigateToDetails}
      style={{ cursor: 'pointer' }}
    >
      <FlexGrid>
        <FlexGridItem cols={11} filled>
          <FlexGrid>
            <FlexGridItem cols={{ desktopLG: 4 }} filled>
              <Box
                display="flex"
                flexDirection="column"
                gap="sm"
                justifyContent="center"
                overflow="hidden"
                marginRight={{ mobileXS: 'md' }}
                paddingY={2}
              >
                <Text
                  color="neutral.gray2"
                  data-testid="schedule-text"
                  mb={0}
                  variant="subtitle-medium-12"
                >
                  {schedule}
                </Text>
                <S.Text
                  color="neutral.gray1"
                  data-testid="title-text"
                  mb={0}
                  variant="subtitle-medium-16"
                >
                  {title}
                </S.Text>
              </Box>
            </FlexGridItem>

            <FlexGridItem cols={{ desktopLG: 3 }} filled>
              <Box
                display="flex"
                flexDirection="column"
                gap="sm"
                justifyContent="center"
                overflow="hidden"
              >
                <Text color="neutral.gray2" mb={0} variant="subtitle-medium-12">
                  {tBase('confirmations')}
                </Text>
                <S.Text
                  color="neutral.gray1"
                  data-testid="confirmations-text"
                  mb={0}
                  variant="subtitle-medium-16"
                >
                  N/A
                </S.Text>
              </Box>
            </FlexGridItem>

            <FlexGridItem cols={{ desktopLG: 3 }}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height="100%"
                alignItems="flex-start"
              >
                <RecipientTag
                  enableEllipsis
                  variant="neutral"
                  modalTitle={currentRecipientTag.type}
                  recipientType={currentRecipientTag.type}
                  recipients={currentRecipientTag.recipients}
                  size="small"
                />
              </Box>
            </FlexGridItem>

            <FlexGridItem cols={{ desktopLG: 2 }}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                overflow="hidden"
                height="100%"
              >
                <Tag
                  data-testid="status-tag"
                  name={EVENT_STATUS[status]}
                  variant={EVENT_TAG_STATUS_VARIANT[status]}
                  size="small"
                />
              </Box>
            </FlexGridItem>
          </FlexGrid>
        </FlexGridItem>

        <FlexGridItem cols={1} filled>
          <Box position="relative" height="100%">
            <Box position="absolute" top={0} right={0}>
              <SubtleIconButton actions={actions()} align="right" size="lg" />
            </Box>
          </Box>
        </FlexGridItem>
      </FlexGrid>
    </CardComponent>
  );
};

export default Card;
