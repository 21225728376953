import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import autobind from 'autobind-decorator';

import './style.scss';

export const TOP = 'top center';
export const BOTTOM = 'bottom center';
export const LEFT = 'left center';
export const RIGHT = 'right center';

class Popover extends PureComponent {
  static PropTypes = {
    children: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    position: PropTypes.oneOf([TOP, BOTTOM, LEFT, RIGHT]),
  };

  static defaultProps = {
    position: TOP,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  @autobind
  toggleOpen() {
    this.setState((prevState) => {
      const { isOpen } = prevState;

      return { isOpen: !isOpen };
    });
  }

  render() {
    const { content, children, disabled, position } = this.props;

    const { isOpen } = this.state;

    if (disabled) return children;

    return (
      <div className="Popover" onClick={this.toggleOpen}>
        <Popup
          trigger={(_open) => <div>{children}</div>}
          position={position}
          open={isOpen}
        >
          {content}
        </Popup>
      </div>
    );
  }
}

export default Popover;
