import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import {
  Alert,
  Box,
  Button,
  DefaultThemeProps,
  Icon,
  RecipientTag,
  Tag,
  Text,
} from '@agendaedu/ae-web-components';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import {
  AVAILABLE_TO,
  EVENT_STATUS,
  EVENT_TAG_STATUS_VARIANT,
} from 'core/constants/calendar/event';
import formatDatetime from 'core/utils/formatDatetime';
import {
  getEventApprovedStatus,
  getEventDate,
  getRecipientTag,
} from 'core/helper/calendar/event';

import CalendarRouters from 'store/calendar/actions';
import { CalendarState } from 'store/calendar/types/store';

import * as S from './styles';

import AttachmentPreview from 'pages/storage/StorageDetails/components/AttachmentPreview';
import EventApproveModal from 'pages/Calendar/components/Event/Details/Modals/Approve';
import EventDeleteModal from 'pages/Calendar/components/Event/Modals/Delete';
import History from 'components/History';
import Tooltip from 'components/Tooltip';
import Toast from 'components/Toast';
import Skeleton from './skeleton';

import { Props } from './types';

const Informations: React.FC<Props> = ({ eventId }): React.ReactElement => {
  const { t } = useTranslation(['calendar']);
  const dispatch = useDispatch();

  const { colors } = useTheme() as DefaultThemeProps;

  const tBase = useCallback(
    (key: string) => t(`details.tabs.informations.${key}`),
    [t]
  );

  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { fetchEventDetailsRequest } = CalendarRouters;

  const {
    details: { current: event },
  } = useSelector((state: CalendarState) => state.calendar.event);

  const { dataArea } = useSelector((state: RootStateOrAny) => state.root);

  useEffect(() => {
    if (!event) dispatch(fetchEventDetailsRequest(eventId));
  }, [event, dispatch, eventId, fetchEventDetailsRequest]);

  if (!event) return <Skeleton />;

  const {
    attributes: { userPermissions },
    relationships: { versions, classrooms, studentProfiles },
  } = event;

  const recipientTag = getRecipientTag({
    classrooms: classrooms,
    studentProfiles: studentProfiles,
    receiverType: event.attributes.receiverType,
  });

  const disableApproveButton =
    !event.attributes.userPermissions.approve ||
    event.attributes.status === 'processing';

  const disabledEditButton =
    !event.attributes.userPermissions.edit ||
    event.attributes.status === 'processing';

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        id="calendar-informations"
        padding={{ _: 'xl', desktopLG: 'xl2' }}
      >
        <Box display="flex" flexDirection="column">
          {event.attributes.status === 'processing' && (
            <Alert
              data-testid="sending-event-tag"
              mb="xl"
              variant="informative"
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              {tBase('sending_event_tag')}
            </Alert>
          )}

          <Box
            display="flex"
            flexDirection={{ _: 'column', desktopLG: 'row' }}
            gap="sm"
          >
            <Tag
              data-testid="event-status-tag"
              name={EVENT_STATUS[event.attributes.status]}
              variant={EVENT_TAG_STATUS_VARIANT[event.attributes.status]}
            />

            <Tag
              data-testid="category-tag"
              name={tBase('default_tag_label')}
              variant="brand"
            />

            <RecipientTag
              variant="neutral"
              enableEllipsis
              modalTitle={recipientTag.type}
              recipientType={recipientTag.type}
              recipients={recipientTag.recipients}
            />
          </Box>

          <Text
            color="neutral.black"
            data-testid="title-text"
            fontWeight={700}
            mb={0}
            mt="xl"
            variant="headline-h2-bold-24"
          >
            {event.attributes.title}
          </Text>

          <Box
            display="flex"
            mt="lg"
            flexDirection={{ _: 'column', desktopLG: 'row' }}
            gap={{ _: 'lg', desktopLG: 'xl' }}
          >
            <Box
              display="flex"
              flexDirection={{ _: 'column', desktopXL: 'row' }}
              gap={{ _: 'lg', desktopLG: 'xl' }}
            >
              <Box
                display="flex"
                gap="xs"
                flexDirection="row"
                alignItems="center"
              >
                <S.InformationCard>
                  <Icon name="calendar" color={colors.brand.primary.default} />
                </S.InformationCard>

                <Box display="flex" flexDirection="column" gap="sm">
                  <Text
                    variant="subtitle-medium-16"
                    color="neutral.gray2"
                    mb={0}
                  >
                    {tBase('created_at_date_text')}
                  </Text>
                  <Text
                    data-testid="created-at"
                    variant="label-regular-16"
                    color="neutral.black"
                    mb={0}
                  >
                    {formatDatetime(event.attributes.createdAt)}
                  </Text>
                </Box>
              </Box>

              <Box
                display="flex"
                gap="xs"
                flexDirection="row"
                alignItems="center"
              >
                <S.InformationCard>
                  <Icon name="calendar" color={colors.brand.primary.default} />
                </S.InformationCard>

                <Box display="flex" flexDirection="column" gap="sm">
                  <Text
                    variant="subtitle-medium-16"
                    color="neutral.gray2"
                    mb={0}
                  >
                    {tBase('event_date_text')}
                  </Text>
                  <Text variant="label-regular-16" color="neutral.black" mb={0}>
                    {getEventDate(event.attributes)}
                  </Text>
                </Box>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection={{ _: 'column', desktopXL: 'row' }}
              gap={{ _: 'lg', desktopLG: 'xl' }}
            >
              <Box
                display="flex"
                gap="xs"
                flexDirection="row"
                alignItems="center"
              >
                <S.InformationCard>
                  <Icon name="calendar" color={colors.brand.primary.default} />
                </S.InformationCard>

                <Box display="flex" flexDirection="column" gap="sm">
                  <Text
                    variant="subtitle-medium-16"
                    color="neutral.gray2"
                    mb={0}
                  >
                    {tBase('event_limit_date_text')}
                  </Text>
                  <Text variant="label-regular-16" color="neutral.black" mb={0}>
                    {formatDatetime(event.attributes.endDate)}
                  </Text>
                </Box>
              </Box>

              <Box
                display="flex"
                gap="xs"
                flexDirection="row"
                alignItems="center"
              >
                <S.InformationCard>
                  <Icon name="send" color={colors.brand.primary.default} />
                </S.InformationCard>

                <Box display="flex" flexDirection="column" gap="sm">
                  <Text
                    data-testid="available-to-text"
                    variant="subtitle-medium-16"
                    color="neutral.gray2"
                    mb={0}
                  >
                    {tBase('available_to_text')}
                  </Text>
                  <Text
                    data-testid="available-to"
                    variant="label-regular-16"
                    color="neutral.black"
                    mb={0}
                  >
                    {AVAILABLE_TO[event.attributes.availableTo]}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>

          {!!event.attributes.coverImage && (
            <Box display="flex" flexDirection="column" mt="xl" gap="xs">
              <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
                {tBase('cover_image_text')}
              </Text>
              <S.CoverImagePreview>
                <img
                  data-testid="cover-image"
                  alt={t('cover_image_text')}
                  loading="lazy"
                  src={event.attributes.coverImage}
                />
              </S.CoverImagePreview>
            </Box>
          )}

          <Box display="flex" flexDirection="column" mt="lg" gap="xs">
            <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
              {tBase('description_text')}
            </Text>

            <Text variant="body-regular-16" color="neutral.gray1" mb={0}>
              {parse(event.attributes.description)}
            </Text>
          </Box>

          {!!event.attributes.location && (
            <Box display="flex" flexDirection="column" gap="xs" mt="lg">
              <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
                {tBase('location_field_label')}
              </Text>
              <Text
                data-testid="location"
                variant="body-regular-16"
                color="neutral.gray1"
                mb={0}
              >
                {event.attributes.location}
              </Text>
            </Box>
          )}

          {event.attributes.attachments?.length > 0 && (
            <Box
              display="flex"
              flexDirection="column"
              gap="xs"
              mt="lg"
              maxWidth="617px"
            >
              <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
                {tBase('attachment_label')}
              </Text>

              <Box display="flex" flexDirection="column" gap="xs">
                {event.attributes.attachments?.map((attachment) => (
                  <AttachmentPreview
                    key={attachment.name}
                    attachment={attachment}
                  />
                ))}
              </Box>
            </Box>
          )}

          {!!event.attributes.conferenceLink && (
            <>
              <Text
                color="neutral.gray2"
                mb={0}
                mt="lg"
                variant="subtitle-medium-14"
              >
                {tBase('conference_link_label')}
              </Text>

              <S.ConferenceLinkWrapper>
                <Icon name="rec" />
                <S.ConferenceLink
                  data-testid="conference-link"
                  href={event.attributes.conferenceLink}
                  target="_blank"
                >
                  {event.attributes.conferenceLink}
                </S.ConferenceLink>
              </S.ConferenceLinkWrapper>
            </>
          )}

          {!!event.attributes.notificationDate && (
            <Box display="flex" flexDirection="column" gap="xs" mt="lg">
              <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
                {tBase('reminder_text')}
              </Text>
              <Text variant="body-regular-16" color="neutral.gray1" mb={0}>
                {formatDatetime(
                  event.attributes.notificationDate,
                  tBase('reminder_date_format')
                )}
              </Text>
            </Box>
          )}

          <S.HistoryWrapper>
            <History data={versions.data as undefined} isModalList />
          </S.HistoryWrapper>

          {!event.attributes.deletedAt && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="row" gap="sm">
                <Tooltip
                  content={tBase('not_permission_actions_label')}
                  disabled={userPermissions.approve}
                >
                  <Button
                    data-testid="approve-button"
                    disabled={disableApproveButton}
                    onClick={() => setShowApproveModal(true)}
                  >
                    {getEventApprovedStatus(event.attributes.status)}
                  </Button>
                </Tooltip>
                <Tooltip
                  content={tBase('not_permission_actions_label')}
                  disabled={userPermissions.edit}
                >
                  <Link to={`/${dataArea}/calendar_events/${eventId}/edit`}>
                    <Button
                      data-testid="edit-button"
                      disabled={disabledEditButton}
                      isOnlyIcon
                      icon="pencil"
                      variant="secondary"
                    />
                  </Link>
                </Tooltip>
              </Box>

              <Tooltip
                content={tBase('not_permission_actions_label')}
                disabled={userPermissions.delete}
              >
                <Button
                  contextVariant="danger"
                  data-testid="delete-button"
                  disabled={!userPermissions.delete}
                  icon="trash-bin"
                  isOnlyIcon
                  onClick={() => setShowDeleteModal(true)}
                  variant="secondary"
                />
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>

      <EventApproveModal
        action={event.attributes.status === 'sent' ? 'resend' : 'approve'}
        eventId={event.id}
        isOpen={showApproveModal}
        onClose={() => setShowApproveModal(false)}
      />

      <EventDeleteModal
        action="details"
        eventId={event.id}
        eventName={event.attributes.title}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />

      <Toast />
    </>
  );
};

export default Informations;
